import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  public workExperiences = {
    viewExperiences: true,
    experience: [
      {
        role: "Senior Software Engineer",
        company: "Oracle",
        color: "#FF5733",
        companylogo: "../../../assets/images/oracle.png",
        date: "April 2021 – Till Date"
      },
      {
        role: "Senior Associate Consultant",
        company: "Infosys",
        color: "#3781c2",
        companylogo: "../../../assets/images/infosys.png",
        date: "Sep 2019 - April 2021"
      },
      {
        role: "I.T.Analyst",
        company: "TCS",
        color: "#FFB833",
        companylogo: "../../../assets/images/tcs.jpg",
        date: "Dec 2014 – Sep 2019"
      }
      // descBullets: [
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      // ]
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}

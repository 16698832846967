import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {
  socialMediaLinks = {

    github: "https://github.com/ravikarrii",
    linkedin: "https://www.linkedin.com/in/ravi-shanker-reddy-karri-9299b71b/",
    gmail: "ravishanker.karri@gmail.com",
    instagram : "https://www.instagram.com/ravikarrii/",
    facebook: "https://www.facebook.com/RaviKarrii/"
  };
  constructor() { }

  ngOnInit(): void {
  }

}


<div class="main" id="skills">
  <div class="skills-main-div">
      <div class="skills-image-div" wmAnimate="fadeInRight" aos speed="normal">
        <img alt="Sarthak Working" src="../../assets/images/manHappy.svg" class="skills-image-div">
      </div>
      <div class="skills-text-div" wmAnimate="fadeInLeft" aos speed="normal">
        <h1 class="skills-heading">{{skillsSection.title}}</h1>
        <p class="subTitle skills-text-subtitle">{{skillsSection.subTitle}}
          <app-software-skill></app-software-skill>
        <div *ngFor="let skill of skillsSection.skills">
          <p class="subTitle skills-text">{{skill}}</p>
        </div>
      </div>
<!--    </Fade>-->
  </div>
</div>

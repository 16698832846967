<!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">-->
<div class="social-media-div">
  <a href={{socialMediaLinks.github}} class="icon-button github" target="_blank">
    <i class="fab fa-github"></i>
    <span></span>
  </a>
    <a href={{socialMediaLinks.linkedin}} class="icon-button linkedin" target="_blank">
      <i class="fab fa-linkedin-in"></i>
      <span></span>
    </a>
    <a href="{{'mailto:' + socialMediaLinks.gmail}}" class="icon-button google" target="_blank">
      <i class="fab fa-google"></i>
      <span></span>
    </a>
    <a href={{socialMediaLinks.instagram}} class="icon-button instagram" target="_blank">
      <i class="fab fa-instagram"></i>
      <span></span>
    </a>
    <a href={{socialMediaLinks.facebook}} class="icon-button facebook" target="_blank">
      <i class="fab fa-facebook-f"></i>
      <span></span>
    </a>
</div>
